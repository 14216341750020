import React, { useEffect, useState } from 'react';
import Header from './Header';

const CommonNavbar = ({ classes, showSearchIcon, showAccountIcon, logoStyle, isDark, allowBlur = true }) => {
    const [scrolled, setScrolled] = useState(false)
    useEffect(() => {
        let lastScrollTop = 77;
        const navbar = document.querySelector('#common_navbar');
        const header = document.querySelector("#kwn-desktop-header-ver2");
        if (navbar && header) {
            window.addEventListener('scroll', () => {
                const lastScroll = window.scrollY;
                if (lastScroll > lastScrollTop) {
                    navbar.style.top = '5px';
                    setScrolled(true)
                }
                if (lastScroll < lastScrollTop) {
                    navbar.style.top = '5px';
                    setScrolled(true)
                }
                if (lastScroll === 0) {
                    header.classList.remove("add-bg-white");
                    navbar.style.top = '0px';
                    setScrolled(false)
                    if (isDark) {
                        header.classList.add("header-dark");
                    }
                } else {
                    header.classList.add("add-bg-white");
                    header.classList.remove("header-dark");
                    setScrolled(true)
                }
                lastScrollTop = lastScroll;
            });
        }
    }, [isDark]);

    const [bgBlur, setBgBlur] = useState(false);

    return (
        <div id='common_navbar' className={`${bgBlur && scrolled && allowBlur ? 'blur-bg' : ''}`}>
            <Header
                classes={classes}
                logoStyle={logoStyle}
                showSearchIcon={showSearchIcon}
                showAccountIcon={showAccountIcon}
                isDark={isDark}
                setBgBlur={setBgBlur}
            />
        </div>
    );
};

export default CommonNavbar;
